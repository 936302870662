@import '@/assets/scss/_variables.scss';
.CurrencyFilter {
  .ExtraFilter_drowDown {
    display: grid;
    align-items: center;
    grid-template-areas: 'all search arrow' 'list list list';
    grid-template-rows: 48px auto;
    grid-template-columns: auto 1fr auto;
    background-color: #345375;
    min-width: 180px;

    .extra-filter-arrow {
        grid-area: arrow;
    }
  }

  &_search {
    grid-area: search;
    border-bottom: 1px solid rgba(#A6AEBF, .21);
    outline: none;
    color: inherit;
    font-size: 10px;
    line-height: 12px;
    padding: 3px 0 3px 13px;
    background-image: url('~@/assets/img/auth/search.svg');
    background-position: 0 50%;
    background-repeat: no-repeat;
    margin: 0 0 0 12px;

    &::placeholder {
      color: #fff;
    }

    @media only screen and (min-width: $large-width) {
      padding: 3px 0 3px 15px;
      background-size: 12px 12px;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &_currencyList {
    grid-area: list;
    display: flex;
    padding: 14px 12px;
    background-color: #2D4D70;

    &_Empty {
      padding: 12px;
      font-size: 14px;
      line-height: 16px;
      color: #A6AEBF;
    }

    &Group {
      flex-grow: 1;
      margin: 0 10px 0 0;

      &:last-child {
        margin: 0;
      }

      &_title {
        margin: 0 0 6px;
        font-size: 10px;
        line-height: 12px;
        color: #A6AEBF;
        text-transform: uppercase;

        @media only screen and (min-width: $large-width) {
          font-size: 14px;
          line-height: 16px;
        }
      }

      &Items {
        row-gap: 6px;
        column-gap: 6px;
        display: flex;
        flex-wrap: wrap;
      }

      .CurrencyIcon {
        flex-basis: 54px;

        @media only screen and (min-width: $large-width) {
          flex-basis: 70px;
        }
      }
    }
  }
}