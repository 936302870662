@import '@/assets/scss/_variables.scss';
.StatusFilter {
  .ExtraFilter_drowDown {
    display: grid;
    align-items: center;
    grid-template-areas: 'all arrow' 'list list';
    grid-template-rows: 48px auto;
    grid-template-columns: 1fr auto;
  }

  &_statusList {
    grid-area: list;
    display: grid;
    row-gap: 4px;
    column-gap: 4px;
    grid-template-columns: repeat(auto-fit, minmax(74px, 1fr));
    padding: 0 12px 14px;

    &Item {
      min-width: 74px;
      display: flex;
      height: 23px;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer;
      border-radius: 2px;
      border: 1px solid transparent;
      background: rgba(42, 243, 243, .1);
      padding: 0 8px;

      @media only screen and (min-width: $large-width) {
        height: 26px;
      }

      &--withClose {
        justify-content: space-between;
      }

      &--completed {
        background: #5ABC67;
      }

      &--waiting {
        color: #F0C854;
        background: rgba(#F0C854, .1);
      }

      &--new {
        color: #2AF3F3;
      }

      &--pending {
        color: #2AF3F3;
      }

      &--success {
        color: #5ABC67;
        background: rgba(#5ABC67, .1);
      }

      &--cancelled {
        color: #5ABC67;
        background: rgba(#5ABC67, .1);
      }

      &--cancelling {
        color: #5ABC67;
        background: #FFF;
      }

      &--error {
        background: #FF556D;
      }

      &--blocked {
        color: #FF556D;
        background: rgba(255, 85, 109, 0.1);
        border-color: #FF556D;
      }

      &--expired {
        background: rgba(#ff556d, 0.1);
        color: #ff556d;
      }

      &--failed {
        background: rgba(#ff556d, 0.1);
        color: #ff556d;
      }

      &_title {
        font-size: 12px;
        line-height: 14px;

        @media only screen and (min-width: $large-width) {
          font-size: 14px;
          line-height: 16px;
        }
      }

      &_icon {
        margin: 0 0 0 4px;
        font-size: 12px;
        line-height: 14px;

        @media only screen and (min-width: $large-width) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}