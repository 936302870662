@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';



















































































































































.DatePicker_root {
  color: #fff;
  box-shadow: 0 18px 34px rgba(34, 39, 47, 0.55);
  border-radius: 4px;
  position: absolute;
  top: 100%;

  .DatePicker_wrapper {
    padding: 10px;
    background-color: #2d4d70;
    position: relative;
    z-index: 9;

    .DatePicker_heading {
      background: #2d4d70;
      padding: 10px 0;
    }

    .DatePicker_switcher {
      max-width: 290px;
      margin: 0 auto;
    }

    .v-picker.v-picker--date {
      border: none;

      .v-date-picker-header__value {
        display: flex;
        position: relative;
        white-space: nowrap;
      }

      .tab-reverse-transition-leave-to,
      .tab-transition-leave-to,
      .tab-reverse-transition-enter-to,
      .tab-transition-enter-to {
        position: absolute !important;
        transition: none !important;
        display: none !important;
        opacity: 0 !important;
        visibility: hidden !important;
      }

      &.theme--dark {
        background: #2d4d70;

        .v-picker__title {
          display: none;
        }

        .v-picker__body {
          background-color: #2d4d70;

          .v-date-picker-table {
            table {
              tbody {
                tr {
                  td {
                    button {
                      &.v-btn--active {
                        border: none;
                        background-color: rgba(#2af3f3, 0.1) !important;
                        color: #2af3f3;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.DatePicker_btns {
  button {
    margin: 0 5px;
  }
}
